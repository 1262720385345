import * as React from "react"
import SiteContainer from "../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import analytics from "../../images/vectors/undraw_Analytics_re_dkf8.svg"
import attribution from "../../images/vectors/undraw_Business_analytics_64el.svg"
import audiences from "../../images/vectors/undraw_having_fun_re_vj4h.svg"
import automation from '../../images/vectors/undraw_functions_egi3.svg';

import PricingSection from "../../components/PricingSection";
import Logout from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import SegmentIcon from "@mui/icons-material/Segment";
import AccountTree from "@mui/icons-material/AccountTree";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Solutions</title>
                <meta name="description" content="Firstparty has solutions for developers to build marketing automations"></meta>
                
            </Helmet>

            <section className="py-10">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-2 fw-bold">Stop Paying for Black Box Marketing Software
                            </h1>
                            <p className="lead text-primary mb-5 fw-bold">
                                Take control of your marketing data 
                            </p>
                            <p className="lead text-muted mb-5">
                                
                            </p>
                            <p className="mb-7 mb-md-9">
                                <a className="btn btn-primary shadow lift mr-5" href="/signup/">
                                    Get Started with 10 Million Free Events<i className="fe fe-arrow-right ms-2" />
                                </a>
                                
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className="section bg-secondary pt-10 pb-3 pt-md-12 pb-md-5">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col text-center">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0" src={analytics} alt="..." />
                        </div>
                        <div className="col-12 col-md-7">
                            <h6 className="text-white text-uppercase">
                                Analytics
                            </h6>
                            <h2 className="text-white">
                                Own all of your marketing Analytics data
                            </h2>
                            <div className="lead text-light mb-5">
                                Firstparty uses first-party cookies to collect highly accurate website visitor activites,
                                and moves that data to a warehouse you control.
                            </div>
                            <a href="/solutions/analytics/" className="btn btn-white">Explore Analytics <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x text-secondary">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="section bg-dark pt-10 pt-md-13 pb-10">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col order-md-2 text-center">
                            <img className="img-fluid" src={automation} alt="..." />
                        </div>
                        <div className="col-12 col-md-6 order-md-1">
                            <h6 className="text-white text-uppercase">
                                Automation
                            </h6>
                            <h2 className="text-white">
                                Build powerful automations
                            </h2>
                            <div className="lead text-light mb-5">
                                React to collected Events in realtime with a simple rules engine that lets you update
                                customer information, push data to an external service, or even run custom Javascript
                                on any Event Firstparty collects.
                            </div>
                            <a href="/solutions/automation/" className="btn btn-light">Explore Automation <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section bg-secondary pt-10 pb-1">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col text-center">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0 pe-5" src={attribution} alt="..." />
                        </div>
                        <div className="col-12 col-md-6">
                            <h6 className="text-white text-uppercase">
                                Attribution
                            </h6>
                            <h2 className="text-white">
                                Optimize campaigns for the business objectives you actually care about
                            </h2>
                            <div className="lead text-light mb-5">
                                Know every channel, source, pageview, click, and event that resulted in a closed-won deal. Identify exactly which marketing efforts result in not just leads, but connected phone calls, pipeline generation, and revenue.
                            </div>
                            <a href="/solutions/attribution/" className="btn btn-primary">Explore Attribution <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x text-secondary">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="section bg-black pt-10 pb-3 pt-md-14 pb-md-5">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col order-md-2 text-center">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0" src={audiences} alt="..." />
                        </div>
                        <div className="col-12 col-md-7 order-md-1">
                            <h6 className="text-white text-uppercase">
                                Audiences
                            </h6>
                            <h2 className="text-white">
                                Create Audiences from any source, including offline data
                            </h2>
                            <div className="lead text-muted-80 mb-5">
                                Create Segments of Events and build Audiences of individuals from web activity combined with
                                data from other marketing tools, CRM, internal databases, and more.
                            </div>
                            <a href="/solutions/audiences/" className="btn btn-secondary">Explore Audiences <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>



            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
